import {
    BookmarkAltIcon,
    BookOpenIcon,
    RssIcon,
    ViewListIcon
}                                   from '@heroicons/react/outline'
import { ChevronRightIcon }         from '@heroicons/react/solid'
import { graphql }                  from 'gatsby'
import React, { FunctionComponent } from 'react'

import Layout from '../components/layout'
import SEO    from '../components/seo'

export const query = graphql`
    query PagesForSitemap {
        allSanityRoute {
            edges {
                node {
                    slug {
                        current
                    }
                    page {
                        title
                    }
                }
            }
        }
    }

`

interface SitemapProps {
    data: {
        allSanityRoute: {
            edges: {
                node: {
                    page: { title: string },
                    slug: { current: string }
                }
            }[]
        }
    }
}

const Sitemap: FunctionComponent<SitemapProps> = (props) => {

    console.log(props)

    const routes = props.data.allSanityRoute.edges

    const links = [
        {title: 'Projects', description: `Learn about Open Law's initiatives`, icon: BookOpenIcon},
        {title: 'Access', description: 'See options for accessing Open Law projects and services', icon: ViewListIcon},
        {title: 'Webinars', description: 'Improve your legal research speed and know-how', icon: BookmarkAltIcon},
        {title: 'Events', description: 'Register for a training session', icon: RssIcon}
    ]

    return (
        <Layout>
            <SEO title="Sitemap"/>
            <section className={'max-w-7xl mx-auto'}>
                <section>
                    <div className="bg-white">
                        <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="max-w-xl mx-auto py-16 sm:py-24">
                                <div className="text-center">
                                    <p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">
                                        Sitemap
                                    </p>
                                    <h2 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        Find a page
                                    </h2>
                                    <p className="mt-2 text-lg text-gray-500">Every page on Open Law.</p>
                                </div>
                            </div>
                        </main>
                    </div>
                </section>

                <ul className={'list-disc px-12 sm:p-0 sm:flex sm:flex-wrap'}>
                    {
                        routes ? routes.sort((a, b) => a.node.page.title > b.node.page.title ? 1 : 0)
                            .map(({node}, idx) => (
                                    <li className={'sm:w-1/3 mb-2'}>
                                        <a className={'cursor-pointer text-blue-800'}
                                           href={`/${node.slug.current}`}>{node.page.title}</a>
                                    </li>
                                )
                            ) : null
                    }
                </ul>


                <div className="max-w-7xl mt-12 mb-12">
                    <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Popular
                        pages</h2>
                    <ul role="list"
                        className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                        {
                            links.map((link, linkIdx) => (
                                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-blue-50">
                      <link.icon className="h-6 w-6 text-blue-700" aria-hidden="true"/>
                    </span>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <h3 className="text-base font-medium text-gray-900">
                      <span
                          className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <a href={`/${link.title.toLowerCase()}`} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true"/>
                            {link.title}
                        </a>
                      </span>
                                        </h3>
                                        <p className="text-base text-gray-500">{link.description}</p>
                                    </div>
                                    <div className="flex-shrink-0 self-center">
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400"
                                                          aria-hidden="true"/>
                                    </div>
                                </li>
                            ))}
                    </ul>
                    <div className="mt-8">
                        <a href="/" className="text-base font-medium text-blue-600 hover:text-blue-500">
                            Or go back home
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>

            </section>

        </Layout>
    )
}

export default Sitemap
